<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">GAS SLIP LIQUIDATION REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
        <v-col md="3" cols="12">
          <v-select
            v-model="duration"
            dense
            label="Duration"
            :items="['1-15', '16-30/31']"
            :rules="rules.combobox_rule"
            @change="monthly_remittances"
          ></v-select>
        </v-col>
        <span class="headline font-weight-light">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
      </v-row>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">PO#</th>
              <th class="text-center text-uppercase">Date</th>
              <th class="text-center text-uppercase">Particular</th>
              <th class="text-center text-uppercase">Amount</th>
              <th class="text-center text-uppercase">Auto</th>
              <th class="text-center text-uppercase">Driver</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list_of_gas_slip_data" :key="item.id">
              <td>{{ item.gas_slip.po_no }}</td>
              <td class="text-center">
                {{ item.date }}
              </td>
              <td class="text-center">
                {{ item.particulars }}
              </td>
              <td class="text-center">
                {{ item.amount }}
              </td>
              <td class="text-center">
                {{ item.auto_name.auto_name+' ('+item.auto_name.plate_no+')'}}
              </td>
              <td class="text-center">
                {{ item.driver}}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiPrinter} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      month_of: '',
      month_of_items: [],

      list_of_gas_slip_data: [],
      duration: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPrinter,
        },
      }
    },
    created() {
      this.get_transaction_month_history({
        branch_id: this.branch_id,
      })
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id', 'branch', 'branch_address', 'branch_contact_no']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('gas_slip_liquidation', ['get_gas_slip_liquidations_report']),
      monthly_remittances() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('month_of', this.month_of)
        data.append('duration', this.duration)
        this.get_gas_slip_liquidations_report(data)
          .then(response => {
            this.list_of_gas_slip_data = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print() {
        var imgData = this.company_logo
        var payments_array = []
        var month = this.month_of
        payments_array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'PO #', alignment: 'center', style: 'label'},
            {text: 'Date', alignment: 'center', style: 'label'},
            {text: 'Particular', alignment: 'center', style: 'label'},
            {text: 'Plate #', alignment: 'center', style: 'label'},
            {text: 'Driver', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
          ]
        )
        if (this.list_of_gas_slip_data.length > 0) {
          var totals = 0
          this.list_of_gas_slip_data.forEach(function (item, index, payment) {
            totals = parseFloat(item.amount) + totals
            payments_array.push(
              [
                {text: item.id, alignment: 'left'},
                {text: item.po_no, alignment: 'left'},
                {text: item.date, alignment: 'left'},
                {text: item.particulars, alignment: 'left'},
                {
                  text: item.auto_name.auto_name + ' (' + item.auto_name.plate_no + ')',
                  alignment: 'left'
                },
                {text: item.driver, alignment: 'left'},
                {
                  text: (item.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left'
                },
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: (totals / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'GAS SLIP REPORT', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            {text: 'Duration: ' + this.duration, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [40, 50, 60, 60, 140, 70, 50],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Chapel Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 10
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
